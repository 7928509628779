mat-form-field[readonly="true"].mat-form-field-type-mat-input .mat-form-field-flex {
  border: none !important;
}

input[readonly] {
  border: none !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  cursor: text !important;
}

input[readonly]::placeholder {
  color: transparent;
}

input[readonly][disabled] {
  color: red;
}

input[readonly]:focus {
  outline: none;
}


mat-form-field[readonly="true"].mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-input-element:disabled {
  background-color: transparent !important;
}

mat-form-field[readonly="true"].mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-suffix {
  display: none;
}
