@import 'variables.scss';

.cellLinkSelection {
  color: $xpo-blue--800 !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.cellRightAligned {
  justify-content: right;
}

.red-text {
  color: $xpo-brand-red--700;
}

.totals-row {
  background-color: $xpo-grey--150 !important;
  font-weight: $xpo-fontWeight--bolder;
  color: $xpo-grey--900 !important;
}

// TODO: Remove the following class once the @xpo-ltl/ngx-ltl-grid is updated (check if the global checkbox is displaying)
.ag-header-select-all {
  display: inherit !important;
}
