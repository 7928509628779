/* You can add global styles to this file, and also import other style files */
@import 'variables.scss';
@import 'text-styles.scss';
@import 'grid-styles';
@import 'icons-styles';
@import 'disabled-input.scss';
@import 'readonly-input.scss';

:root {
  --component-height: calc(100vh - 104px);
}

.required:after {
  content: " *";
  color: $xpo-red--400;
}

mat-form-field.mat-form-field-type-mat-input .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  -webkit-transform: none !important;
  transform: none !important;
}

input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.highlight-modified-section {
  background-color: $xpo-yellow--350;
}
