.view-split-vertical-transform {
  transform: rotate(180deg);
}

.swap-transform {
  transform: rotate(90deg);
}

.replay-transform {
  transform: scaleX(-1);
}
