@import 'variables.scss';

.rating-text {
  &--info {
    font-style: italic;
    color: $xpo-grey--900;
  }
  &--alert {
    color: $xpo-red--150;
  }
}
